//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TimeHelper } from '@/services/helpers.js';
import * as moment from 'moment-timezone';
// import moment from 'moment';

export default {
  name: 'EventTable',
  data() {
    return {
      selected: [],
      pagination: {},
    };
  },
  props: [
    'eventList',
    'inProgress',
    'columns',
    'showActions',
    'initialPagination',
    'hidePagination',
    'hideHeader',
    'hideFooter',
    'hideSelection',
  ],
  methods: {
    isSameDate(a, b) {
      let d1 = moment(a)
        .tz(TimeHelper.getDefaultTimezone())
        .format('dddd, MMMM Do YYYY');
      let d2 = moment(b)
        .tz(TimeHelper.getDefaultTimezone())
        .format('dddd, MMMM Do YYYY');
      return d1 == d2;
    },
    onRequest(val) {
      this.$emit('loadEvents', val);
    },
    onRowClick(val, row) {
      this.$emit('onRowClick', row);
    },
    prevPage() {
      if (this.pagination.hasPrevPage) {
        this.pagination.page--;
        this.onRequest(this);
      }
    },
    nextPage() {
      if (this.pagination.hasNextPage) {
        this.pagination.page++;
        this.onRequest(this);
      }
    },
  },
  mounted() {
    this.pagination = this.initialPagination;
  },
  computed: {},
  watch: {
    selected(newval, oldval) {
      if (newval != oldval) this.$emit('selected', newval);
    },
    pagination(newval, oldval) {
      if (newval != oldval) this.$emit('pagination', newval);
    },
  },
};
